import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import firebaseConfig from "../firebaseConfig";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import "./style/populer.css";

const Populer = () => {
  const [comicData, setComicData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const navigate = useNavigate();

  const [imageStatus, setImageStatus] = useState({});

  useEffect(() => {
    displayData.forEach((komik) => {
      const img = new Image();
      img.src = komik.imgSrc;

      img.onload = () => {
        setImageStatus((prevStatus) => ({
          ...prevStatus,
          [komik.imgSrc]: true,
        }));
      };

      img.onerror = () => {
        setImageStatus((prevStatus) => ({
          ...prevStatus,
          [komik.imgSrc]: false,
        }));
      };
    });
  }, [displayData]);

  // Fetch data from Firestore
  useEffect(() => {
    const fetchData = async () => {
      try {
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
        const snapshot = await getDocs(
          collection(db, "v2-komik_populer_minggu_ini")
        );
        // console.log(snapshot);
        let comic = [];
        snapshot.forEach((doc) => {
          comic.push({ ...doc.data(), id: doc.id });
        });
        // Sort comics by title
        comic.sort((a, b) => (a.judul > b.judul ? 1 : -1));
        setComicData(comic);
      } catch (error) {
        console.error("Error reading documents:", error);
      }
    };
    fetchData();
  }, []);

  // Update display data based on screen size
  useEffect(() => {
    const updateDisplayData = () => {
      const isDesktop = window.innerWidth >= 768;
      setDisplayData(comicData.slice(0, isDesktop ? 9 : 9));
    };

    updateDisplayData();
    window.addEventListener("resize", updateDisplayData);

    return () => window.removeEventListener("resize", updateDisplayData);
  }, [comicData]);

  // Event handler when an image is clicked
  const handleComicClick = (path, chapter) => {
    // navigate(`/${comic}`); // Navigate to the DetailKomik page with the comic path
    const formattedChapterTitle = chapter
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-+|-+$/g, "");

    console.log("handleComik", path, formattedChapterTitle);
    console.log(path, formattedChapterTitle);
    navigate(`/${path}/${formattedChapterTitle}`); // Navigate to the DetailKomik page with the comic path
    // navigate(`/${path}`);
  };

  return (
    <div className="populer">
      <div className="title">
        <h1>Bebas Baca Komik</h1>
      </div>
      <div className="title-desc">
        Selamat datang di website Bebas Baca Komik, situs baca komik, baca
        manga, baca manhua, dan baca manhwa terpopuler di Indonesia. Tanpa iklan
        yang mengganggu dan hanya di BebasBacaKomik.com kamu cukup scrolling
        untuk melihat chapter berikutnya, mudah dan tidak ribet.
      </div>
      <div className="sub-title">Bebas Baca Komik Populer Minggu Ini</div>
      <div className="grid-container">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4 px-3 sm:px">
          {/* {console.log("displayData-123", displayData)} */}
          {displayData.map((komik, index) => (
            <div
              key={index}
              className="relative flex flex-col items-center"
              onClick={() => handleComicClick(komik.path, komik.chapter)}
            >
              <img
                src={
                  imageStatus[komik.imgSrc]
                    ? komik.imgSrc
                    : "https://bebasbacakomik.com/logo192.png"
                }
                alt={komik.judul}
                className="cursor-pointer hover:opacity-80 transition duration-300 rounded-lg"
                style={{
                  width: "calc(100% + 2px)",
                  height: "127px",
                  margin: "-1px",
                  boxShadow: "#e0e0e0 0 0 2px 0",
                }}
                onClick={() => handleComicClick(komik.path, komik.chapter)}
              />
              <div
                className="text-left mt-2 font-bold text-gray-800 hover:text-indigo-600 transition duration-300 cursor-pointer mb-0"
                onClick={() => handleComicClick(komik.path, komik.chapter)}
              >
                <h3 className="overflow-hidden whitespace-nowrap text-ellipsis">
                  {komik.judul && komik.judul.length > 12
                    ? `${komik.judul.slice(0, 22)}...`
                    : komik.judul}
                </h3>
              </div>
              <span>{komik.genre}</span>
              <div
                style={{
                  float: "left",
                  width: "calc(100% - 20px)",
                  marginTop: "10px",
                  padding: "6px 0",
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: 400,
                  background: "#f0f2f5",
                  color: "#424242",
                  marginLeft: "10px",
                  marginBottom: "10px",
                }}
              >
                {komik.chapter}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Populer;
