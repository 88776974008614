import React from "react";
import "./style/Footer.css"; // Stylesheet for Footer

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-gray-700 via-gray-900 to-black text-white py-4 mt-8">
      <div className="border-t border-gray-500"></div>{" "}
      {/* Garis panjang abu-abu */}
      <p className="text-center mt-4">
        &copy; {new Date().getFullYear()} BebasBacaKomik.com version v.2.0.2{/* Copyright */}
      </p>
    </footer>
  );
};

export default Footer;
