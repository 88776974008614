// firebaseConfig.js
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

  // testing mark-mathhew
  // apiKey: "AIzaSyBq1ByzsPNrrCWRfOi1Mrz1FunxNmLXKrw",
  // authDomain: "test-proj-f31f2.firebaseapp.com",
  // databaseURL: "https://test-proj-f31f2-default-rtdb.firebaseio.com",
  // projectId: "test-proj-f31f2",
  // storageBucket: "test-proj-f31f2.appspot.com",
  // messagingSenderId: "879357228285",
  // appId: "1:879357228285:web:8335f5f89fbad9d64eba94"
};

module.exports = firebaseConfig;
