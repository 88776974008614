import React, { useState, useEffect } from "react";
import "./style/SubHeader.css"; // Import the CSS file
import { useNavigate } from "react-router-dom";

const SubHeader = () => {
  const navigate = useNavigate();

  const [activeNav, setActiveNav] = useState("#home");

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector(".header");
      if (window.scrollY >= 80) {
        header?.classList.add("scroll-header");
      } else {
        header?.classList.remove("scroll-header");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScrollRak = () => {
    const aboutSection = document.querySelector("#about-section");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveNav("#about");
  };

  const handleAboutPage = () => {
    navigate("/about"); // Arahkan ke path /about
  };

  return (
    <div className="subheader-container" id="sub-header">
      <button className="subheader-button">
        <a
          href="#genre"
          onClick={handleScrollRak}
          className={
            activeNav === "#genre"
              ? "subheader-button active-link"
              : "subheader-button"
          }
        >
          <i className="uil uil-user nav__icon"></i> Genre
        </a>
      </button>
      <div className="subheader-container">
        <a
          href="#rak-buku"
          onClick={handleScrollRak}
          className={
            activeNav === "#rak-buku"
              ? "subheader-button active-link"
              : "subheader-button"
          }
        >
          <i className="uil uil-user nav__icon"></i> Rak Buku
        </a>
      </div>
      <button className="subheader-button">Daftar Komik</button>
      <button className="subheader-button" onClick={handleAboutPage}>
        About
      </button>{" "}
    </div>
  );
};

export default SubHeader;
