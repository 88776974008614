import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Genre = () => {
  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);
  const [genreLimit, setGenreLimit] = useState(8); // Set default limit untuk mobile

  // Daftar genre
  const genres = [
    "4-Koma",
    "Action",
    "Adaptation",
    "Adult",
    "Adventure",
    "Anthology",
    "Bully",
    "Comedy",
    "Comedy",
    "Cooking",
    "Crime",
    "Crossdressing",
    "Dark Fantasy",
    "Delinquent",
    "Delinquents",
    "Demon",
    "Demons",
    "Doujinshi",
    "Drama",
    "Ecchi",
    "Fantasy",
    "Full Color",
    "Game",
    "Games",
    "Gang",
    "Gender Bender",
    "Genderswap",
    "Ghosts",
    "Girls' Love",
    "Gyaru",
    "Harem",
    "Hero",
    "Historical",
    "Horror",
    "Incest",
    "Isekai",
    "Josei",
    "Leveling",
    "Loli",
    "Lolicon",
    "Long Strip",
    "Mafia",
    "Magi",
    "Magic",
    "Magical Girls",
    "Martial Art",
    "Martial Arts",
    "Mature",
    "Mecha",
    "Medical",
    "Military",
    "Mirror",
    "Modern",
    "Monster Girls",
    "Monsters",
    "Murim",
    "Music",
    "Mystery",
    "Necromancer",
    "Official Colored",
    "One-Shot",
    "Oneshot",
    "Overpowered",
    "Parody",
    "Pets",
    "Philosophical",
    "Police",
    "Post-Apocalyptic",
    "Project",
    "Psychological",
    "Regression",
    "Reincarnation",
    "Revenge",
    "Romance",
    "School",
    "School Life",
    "Sci-fi",
    "Seinen",
    "Seinin",
    "Sexual Violence",
    "Shotacon",
    "Shoujo",
    "Shoujo Ai",
    "Shounen",
    "Shounen Ai",
    "Shounn",
    "Slice of Life",
    "Smut",
    "Sports",
    "Super Power",
    "Superhero",
    "Supernatural",
    "Supranatural",
    "Survival",
    "System",
    "Thriller",
    "Time Travel",
    "Tragedy",
    "Vampire",
    "Vampires",
    "Video Games",
    "Villainess",
    "Violence",
    "Virtual Reality",
    "Web Comic",
    "Webtoons",
    "Wuxia",
    "Yaoi",
    "Yuri",
    "Zombies",
    "gore",
    "gorre",
  ];

  // Fungsi untuk mendeteksi ukuran layar dan mengubah batas genre
  const handleResize = () => {
    if (window.innerWidth >= 768) {
      setGenreLimit(26); // 20 genre untuk desktop
    } else {
      setGenreLimit(12); // 8 genre untuk mobile
    }
  };

  // useEffect untuk mengatur batas awal berdasarkan ukuran layar
  useEffect(() => {
    handleResize(); // Set initial limit
    window.addEventListener("resize", handleResize); // Update on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  // Tampilkan genre berdasarkan batas yang ditentukan
  const displayedGenres = showAll ? genres : genres.slice(0, genreLimit);

  // Menangani klik tombol genre
  const handleClick = (genre) => {
    navigate(`/genre/${genre}`);
  };

  return (
    <div style={{ padding: "5px" }}>
      {/* <h2 style={{ color: "#1E90FF" }}>Lihat Genre</h2> */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "2px",
          fontWeight: "700",
        }}
        id="genre"
      >
        {displayedGenres.map((genre, index) => (
          <button
            key={index}
            onClick={() => handleClick(genre)}
            style={{
              padding: "10px 10px",
              backgroundColor: "#1E90FF",
              color: "#FFFFFF",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "12px",
              height: "30px",
              lineHeight: "0px",
            }}
          >
            {genre}
          </button>
        ))}
      </div>

      {/* Tampilkan tombol "Lebarkan" jika genre melebihi batas yang ditentukan */}
      {genres.length > genreLimit && !showAll && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <button
            onClick={() => setShowAll(true)} // Mengubah state untuk menampilkan seluruh genre
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "#1E90FF",
              textDecoration: "underline",
              fontSize: "16px",
              cursor: "pointer",
              padding: 0,
              fontWeight: "700",
            }}
          >
            Tampilkan seluruh Genre
          </button>
        </div>
      )}
    </div>
  );
};

export default Genre;
