import React, { useState, useEffect } from "react";
import "./style/Home.css"; // Pastikan path sesuai dengan lokasi file CSS Anda
import Header from "../components/Header";
import Populer from "../components/Populer";
import Terpopuler from "../components/KomikTerpopuler";
import MangaTerpopuler from "../components/MangaTerpopuler";
import ManhwaTerpopuler from "../components/ManhwaTerpopuler";
import ManhuaTerpopuler from "../components/ManhuaTerpopuler";
import KomikTerbaru from "../components/KomikTerbaru";
import RakBuku from "../components/RakBuku";
import BacaApa from "../components/BacaApa";
import SedangBerlangsung from "../components/SedangBerlangsung";
import LatestUpdate from "../components/UpdateTerbaru";
import Genre from "../components/Genre";
import Tipe from "../components/Tipe";
import KomikRilisanTerbanyak from "../components/KomikRilisanTerbanyak";
import Footer from "../components/Footer";
import { HelmetProvider, Helmet } from "react-helmet-async";
import loadingGif from "../assets/bebas-baca-komik-loading-gif.gif";
import SubHeader from "./SubHeader";
import ScrollUp from "../components/ScrollUp";

// Komponen utama yang menyusun semua komponen di atas
const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulasikan waktu loading data
    const loadData = async () => {
      // Gantilah bagian ini dengan logika pengambilan data yang sebenarnya
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsLoading(false);
    };

    loadData();
  }, []);

  const siteTitle =
    "Situs Baca Komik Manga, Manhua, Manhwa Gratis - Bebas Baca Komik!";
  const siteDescription =
    "BebasBacaKomik.com! - Download Koleksi lengkap komik manga, manhua, dan manhwa terbaru dan terpopuler dengan berbagai genre, baca online dan offline, nikmati akses tanpa batas!";
  const keywords =
    "download komik, download komik manga, download komik manhua, download komik manhwa, download gratis, download komik terbaru, download komik update tercepat, download genre komik, baca dan download komik online, baca offline, komunitas komik";
  const author = "BebasBacaKomik.com";

  return (
    <HelmetProvider>
      <div>
        <div>
          <Header />
          <SubHeader />
          <div
            className="base"
            style={{
              borderTop: "1px solid #e5e7eb",
              width: "100%",
            }}
          >
            <div className="container">
              <Populer />
              <KomikRilisanTerbanyak />
              <KomikTerbaru />
              <MangaTerpopuler />
              <ManhwaTerpopuler />
              <ManhuaTerpopuler />
              <Genre />
              <RakBuku />
              <Terpopuler />

              <Footer />
            </div>
          </div>
        </div>
        <ScrollUp />


        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
          <meta name="keywords" content={keywords} />
          <meta name="author" content={author} />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />

          {/* Schema Markup untuk Breadcrumbs */}
          <script type="application/ld+json">
            {`{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://bebasbacakomik.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Manga",
        "item": "https://bebasbacakomik.com/tipe/Manga"
      },
            {
        "@type": "ListItem",
        "position": 3,
        "name": "Manhwa",
        "item": "https://bebasbacakomik.com/tipe/Manhwa"
      },
            {
        "@type": "ListItem",
        "position": 4,
        "name": "Manga",
        "item": "https://bebasbacakomik.com/tipe/Manhua"
      },
      {
        "@type": "ListItem",
        "position": 5,
        "name": "Pencarian",
        "item": "https://bebasbacakomik.com/pencarian"
      }
    ]
  }`}
          </script>

          {/* Schema Markup untuk Website */}
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://bebasbacakomik.com",
            "name": "bebasbacakomik.com",
            "publisher": {
              "@type": "Organization",
              "name": "BebasBacaKomik"
            },
            "sameAs": [
              "https://twitter.com/bebasbacakomik",
              "https://www.instagram.com/bebasbacakomik/"
              "https://www.tiktok.com/@bebasbacakomik",
              "https://www.pinterest.com/bebasbacakomik/"
            ],
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://bebasbacakomik.com/pencarian?q={search_term_string}",
              "query-input": "required name=search_term_string"
            }
          }`}
          </script>

          {/* Open Graph Meta Tags */}
          <meta property="og:title" content={siteTitle} />
          <meta property="og:description" content={siteDescription} />
          <meta
            property="og:image"
            content="https://bebasbacakomik.com/logo512.png"
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="bebasbacakomik.com" />

          {/* Twitter Meta Tags */}
          <meta
            name="twitter:card"
            content="https://bebasbacakomik.com/logo512.png"
          />
          <meta name="twitter:title" content={siteTitle} />
          <meta name="twitter:description" content={siteDescription} />
          <meta
            name="twitter:image"
            content="https://bebasbacakomik.com/logo512.png"
          />
          <meta name="twitter:site" content="@bebasbacakomik" />
          <meta name="twitter:creator" content="@bebasbacakomik" />
        </Helmet>
      </div>
    </HelmetProvider>
  );
};

export default Home;
