import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./style/DetailKomik.css";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../contexts/UserContext";
import Login from "../components/Login";
import axios from "axios";
import { getAuth, onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { serverTimestamp } from "firebase/firestore";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const firebaseConfig = require("../firebaseConfig.js");
const { initializeApp } = require("firebase/app");
const {
  getFirestore,
  collection,
  where,
  query,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  runTransaction,
  setDoc,
} = require("firebase/firestore");

// inisiasi secara global
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const DetailKomik = () => {
  const { path } = useParams(); // Mengambil id komik dari URL
  const [comicDetail, setComicDetail] = useState(null);
  const navigate = useNavigate();
  const [showFullSinopsis, setShowFullSinopsis] = useState(false);
  const [loading, setLoading] = useState(true);

  // check user
  const { user, setUser } = useContext(UserContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showData, setShowData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFileId] = useState([]);
  const [message, setMessage] = useState("");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isVisible, setIsVisible] = useState(true);

  const [isClicked, setIsClicked] = useState(false);

  const [isFavorited, setIsFavorited] = useState(false);
  const [isLater, setIsLater] = useState(false);

  const [activeTab, setActiveTab] = useState("chapters");

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleToggleSinopsis = () => {
    setShowFullSinopsis(!showFullSinopsis);
  };

  useEffect(() => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const db = getFirestore();

    console.log("path123", path);

    // Membuat query untuk mencari dokumen dengan nilai "guard-pass" pada kunci "path"
    const q = query(collection(db, "komik_detail"), where("path", "==", path));

    console.log("q123", q);

    // Melakukan query
    getDocs(q)
      .then((querySnapshot) => {
        console.log("querySnapshot", querySnapshot);
        if (querySnapshot.empty) {
          console.log("No documents found matching the query.");
          // Menampilkan pesan bahwa tidak ada detail komik yang ditemukan
        } else {
          const data = querySnapshot.docs.map((doc) => doc.data());
          setComicDetail(data[0]); // Menggunakan data dari dokumen pertama yang ditemukan
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error getting documents: ", err);
        setLoading(false);
      });
  }, [path]); // Memastikan useEffect dipanggil kembali ketika path berubah

  // Fungsi untuk menavigasi ke halaman baca komik
  const handleReadChapter = (chapterTitle) => {
    const title = comicDetail.path;
    console.log("chapterTitle", chapterTitle);

    // Mengubah chapterTitle menjadi huruf kecil dan mengganti spasi atau karakter spesial dengan garis penghubung
    const formattedChapterTitle = chapterTitle
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-+|-+$/g, ""); // Menghapus garis penghubung di awal atau akhir string

    console.log("komik:", title, "/", formattedChapterTitle);

    navigate(`/${title}/${formattedChapterTitle}`);
  };

  const handleClick = (genre) => {
    // console.log(`Genre clicked: ${genre}`);
    navigate(`/genre/${genre}`);
  };

  // later handle
  useEffect(() => {
    if (comicDetail) {
      // Cek apakah comicDetail ada
      const later = JSON.parse(localStorage.getItem("later")) || [];
      setIsLater(later.includes(comicDetail.title));
    }
  }, [comicDetail]);

  const handleLater = (title) => {
    const later = JSON.parse(localStorage.getItem("later")) || [];
    if (later.includes(title)) {
      // Ganti bookmarks dengan later
      const updatedLater = later.filter((laterTitle) => laterTitle !== title);
      localStorage.setItem("later", JSON.stringify(updatedLater));
      setIsLater(false);
    } else {
      later.push(title);
      localStorage.setItem("later", JSON.stringify(later));
      setIsLater(true);
    }
  };

  // console.log("testFavorite", comicDetail);

  useEffect(() => {
    if (comicDetail) {
      // Cek apakah comicDetail ada
      const bookmarks = JSON.parse(localStorage.getItem("bookmarks")) || [];
      setIsFavorited(bookmarks.includes(comicDetail.title));
    }
  }, [comicDetail]);

  const handleClickFavorite = (title) => {
    const bookmarks = JSON.parse(localStorage.getItem("bookmarks")) || [];
    if (bookmarks.includes(title)) {
      const updatedBookmarks = bookmarks.filter(
        (bookmark) => bookmark !== title
      );
      localStorage.setItem("bookmarks", JSON.stringify(updatedBookmarks));
      setIsFavorited(false);
    } else {
      bookmarks.push(title);
      localStorage.setItem("bookmarks", JSON.stringify(bookmarks));
      setIsFavorited(true);
    }
  };

  const handleGenreKomik = (genre) => {
    navigate(`/tipe/${genre}`);
  };

  if (!comicDetail) {
    return <div>Loading...</div>; // Tampilkan loading atau pesan jika comicDetail tidak ada
  }

  if (loading) {
    return (
      <div className="loading-container flex justify-center items-center h-64">
        <div className="flex items-center space-x-4 bg-gray-200 text-gray-800 font-semibold py-3 px-6 rounded-lg shadow-lg">
          <svg
            className="animate-spin h-5 w-5 text-blue-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
            ></path>
          </svg>
          <span>Sedang mendapatkan data...</span>
        </div>
      </div>
    );
  }

  const handleTitleClick = (value) => {
    // Mengubah chapterTitle menjadi huruf kecil dan mengganti spasi atau karakter spesial dengan garis penghubung
    const formattedChapterTitle = value
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-") // Mengubah semua karakter selain huruf dan angka menjadi "-"
      .replace(/^-+|-+$/g, "") // Menghapus "-" di awal atau akhir string
      .replace(/^komik-/, ""); // Menghapus "komik-" di awal string

    console.log("formattedChapterTitle", formattedChapterTitle);

    // Scroll ke atas
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Memberikan efek scroll yang halus
    });

    // Navigasi ke URL baru
    navigate(`/${formattedChapterTitle}`);
  };

  return (
    <HelmetProvider>
      {comicDetail && ( // Conditionally render Helmet content
        <Helmet>
          <title>{comicDetail.title} - BebasBacaKomik.com</title>
          <meta
            name="description"
            content={`Download komik ${comicDetail.title} serta chapter lengkap dan terbaru`}
          />
          <meta
            name="keywords"
            content={`${
              comicDetail.title
            }, baca komik online, ${comicDetail.genres.join(", ")}`}
          />
          <meta
            property="og:title"
            content={`${comicDetail.title} - Baca Komik Online`}
          />
          <meta property="og:description" content={comicDetail.sinopsis} />
          <meta property="og:image" content={comicDetail.coverLink} />
          <script type="application/ld+json">
            {`{
          "@context": "https://schema.org",
          "@type": "ComicStory",
          "name": "${comicDetail.title}",
          "description": "${comicDetail.sinopsis}",
          "image": "${comicDetail.coverLink}",
          "author": {
            "@type": "Person",
            "name": "${comicDetail.author || "Unknown"}"
          },
          "genre": ${JSON.stringify(comicDetail.genres || [])}
        }`}
          </script>
        </Helmet>
      )}
      <div>
        <Header />
        <div className="comic-detail-container">
          {comicDetail && (
            <div className="cover-image-container">
              <img
                src={comicDetail.coverLink}
                alt={comicDetail.title}
                className="cover-image"
              />
            </div>
          )}

          {/* {console.log(comicDetail)} */}
          {comicDetail && isVisible && (
            <>
              <div className="button-container">
                <button
                  className="read-button w-full md:w-200 bg-green-500 text-white font-bold py-2 px-4 rounded-lg shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300"
                  onClick={() =>
                    handleReadChapter(comicDetail.chapters[0].judul)
                  }
                >
                  Mulai Baca
                </button>

                <button
                  className={`later-button w-full md:w-200 text-white font-bold py-2 px-4 rounded-lg shadow-lg transition duration-300 ${
                    isLater ? "bg-[#353333]" : "bg-grey-500 hover:bg-red-600"
                  }`}
                  onClick={() => handleLater(comicDetail.title)}
                >
                  {isLater ? "Sudah Ditandai!" : "Baca Nanti!"}
                </button>

                {/* favorite */}
                <button
                  className={`flex items-center px-4 py-2 rounded transition duration-300 ${
                    isFavorited
                      ? "bg-transparent"
                      : "bg-red-500 hover:bg-red-600"
                  }`}
                  onClick={() => handleClickFavorite(comicDetail.title)}
                >
                  <i
                    className={`fas fa-heart mr-1 ${
                      isFavorited ? "text-pink-500" : "text-gray-200"
                    }`}
                  ></i>
                </button>
              </div>
            </>
          )}

          <div className="comic-detail-info">
            {comicDetail && (
              <div>
                <div className="container-h1">
                  <h1 className="text-4xl font-bold text-center text-[#4164b2] mt-6 mb-0 shadow-sm">
                    {comicDetail.title}
                  </h1>
                  <span style={{ fontSize: "12px", fontStyle: "italic" }}>
                    {"("}
                    {comicDetail.titleIndonesia}
                    {")"}
                  </span>
                </div>
                <div
                  className={`detail-item-status ${
                    comicDetail.status === "Ongoing"
                      ? "status-ongoing"
                      : comicDetail.status === "Complete"
                      ? "status-complete"
                      : "status-unknown"
                  }`}
                >
                  {comicDetail.status}
                </div>
                <div>
                  <strong>Rating : </strong>
                  {comicDetail.rating}
                </div>

                <div>
                  <strong>Umur Pembaca : </strong>
                  {comicDetail.usiaMinimal}
                </div>
                <div>
                  <strong>Genre: </strong>
                  <a
                    role="button"
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      color: "#2e911d",
                    }}
                    onClick={() => handleGenreKomik(comicDetail.jenis)}
                  >
                    {comicDetail.jenis}
                  </a>
                </div>

                <div>
                  <strong>Pengarang : </strong>
                  {comicDetail.pengarang}
                </div>

                <div
                  style={{
                    fontSize: "14px",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                >
                  {comicDetail.description}
                </div>

                <div className="sinopsis-detail bg-gray-100 p-4 rounded-lg shadow-inner">
                  <strong>Sinopsis : </strong>{" "}
                  {comicDetail.sinopsis.length > 150 && !showFullSinopsis ? (
                    <>
                      {comicDetail.sinopsis.slice(0, 150)} ... &nbsp;
                      <button
                        onClick={handleToggleSinopsis}
                        className="read-more text-blue-500 hover:text-blue-700 transition duration-300"
                      >
                        <span className="read-more-text">
                          &nbsp;Baca Selengkapnya
                        </span>
                      </button>
                    </>
                  ) : (
                    comicDetail.sinopsis
                  )}
                </div>

                <div className="detail-item-genre">
                  {comicDetail.genres &&
                    comicDetail.genres.length > 0 &&
                    comicDetail.genres.map((genre, index) => (
                      <button
                        key={index}
                        className="genre-button"
                        onClick={() => handleClick(genre)}
                      >
                        {genre}
                      </button>
                    ))}
                </div>
              </div>
            )}
          </div>

          {/* <div className="blank-space"></div> */}

          <div className="comic-detail-tabs" style={{ background: "white" }}>
            {/* Tab Header */}
            <div className="tab-header border-b-2 border-gray-300 flex justify-center">
              <div
                className={`px-4 py-2 cursor-pointer ${
                  activeTab === "chapters"
                    ? "text-blue-500 border-b-4 border-blue-500"
                    : "text-gray-600 hover:text-blue-500"
                }`}
                onClick={() => setActiveTab("chapters")}
              >
                Chapter
              </div>
              {comicDetail?.Mirip?.length > 0 && (
                <div
                  className={`px-4 py-2 cursor-pointer ${
                    activeTab === "similar"
                      ? "text-blue-500 border-b-4 border-blue-500"
                      : "text-gray-600 hover:text-blue-500"
                  }`}
                  onClick={() => setActiveTab("similar")}
                >
                  Mirip
                </div>
              )}
            </div>

            {/* Tab Content */}
            <div className="tab-content mt-4">
              {activeTab === "chapters" && (
                <div className="chapter-section">
                  <strong className="text-2xl font-semibold text-gray-800 border-b-2 border-gray-300 pb-1 block mt-4 mb-4">
                    Chapter :
                  </strong>
                  <div className="chapter-list">
                    <ul className="chapter-list-ul space-y-4 flex flex-col items-center">
                      {comicDetail?.chapters.map((chapter, index) => (
                        <li key={index} className="chapter-item">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block w-72 md:w-300 text-center font-bold bg-blue-500 text-white hover:bg-blue-700 transition-colors duration-200 py-2 rounded"
                            onClick={() => handleReadChapter(chapter.title)}
                          >
                            {chapter.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}

              {activeTab === "similar" && (
                <div className="similar-list">
                  <ul className="grid grid-cols-2 gap-4">
                    {comicDetail?.Mirip.map((comic, index) => (
                      <li
                        key={index}
                        className="similar-item"
                        onClick={() => handleTitleClick(comic.title)}
                      >
                        <div
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block text-center font-bold text-gray-800 transition-colors duration-200 py-2 rounded bg-gray-100 hover:bg-gray-200"
                        >
                          <img
                            src={comic.imgSrc}
                            alt={comic.title}
                            className="w-full h-32 object-cover rounded-t-md"
                          />
                          <p className="mt-2 px-2 truncate">
                            {comic.title.length > 15
                              ? `${comic.title.slice(0, 15)}...`
                              : comic.title}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          <Snackbar
            open={openSnackbar}
            autoHideDuration={2000}
            onClose={handleSnackbarClose}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleSnackbarClose}
              severity={snackbarSeverity}
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>

          {/* <div className="blank-space"></div> */}
        </div>
      </div>
    </HelmetProvider>
  );
};

export default DetailKomik;
