import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { Helmet, HelmetProvider } from "react-helmet-async";
import loadingGif from "../assets/bebas-baca-komik-loading-gif.gif";

const GenreKomik = () => {
  const { selectedGenre } = useParams();
  const [comics, setComics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const baseUrl =
        window.location.hostname === "localhost"
          ? process.env.REACT_APP_DEVELOPMENT_URL
          : process.env.REACT_APP_PRODUCTION_URL;

      try {
        const response = await fetch(
          `${baseUrl}/public/genre-komik?genre=${selectedGenre}&page=${page}&limit=5`
        );
        console.log("response", response);
        const data = await response.json();
        console.log("Data fetched:", data); // Log data fetched
        setComics(data.results);
        setTotalPages(data.totalPages);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    // console.log(`Fetching data for genre: ${selectedGenre}, page: ${page}`);  // Log fetch info
    fetchData();
  }, [selectedGenre, page]);

  const handleComicClick = (comic) => {
    navigate(`/${comic.path}`);
  };

  const handlePageChange = (newPage) => {
    // console.log("test");
    if (newPage >= 1 && newPage <= totalPages) {
      // console.log(`Changing page to: ${newPage}`);  // Log page change
      setPage(newPage);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src={loadingGif} alt="Loading..." />
      </div>
    );
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>Baca Komik {selectedGenre}</title>
        <meta
          name="description"
          content={`Download berbagai komik menarik dengan genre ${selectedGenre}, mulai dari petualangan seru hingga romansa manis. Jelajahi koleksi komik kami sekarang!`}
        />
        <meta
          name="keywords"
          content={`${selectedGenre}, komik, baca komik, manga, manhwa, webtoon, cerita, hiburan`}
        />
        <meta property="og:title" content={`${selectedGenre} - Komik`} />
        <meta
          property="og:description"
          content={`Download berbagai komik menarik dengan genre ${selectedGenre}, mulai dari petualangan seru hingga romansa manis. Jelajahi koleksi komik kami sekarang!`}
        />
        <meta
          property="og:image"
          content={
            comics[0]?.coverLink || "https://bebasbacakomik.com/logo512.png"
          }
        />
      </Helmet>
      <Header />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2 className="text-2xl font-semibold text-center mt-5 mb-3 text-gray-700">
          <span className="bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 bg-clip-text text-transparent">
            Selected Genre:
          </span>
          <span className="ml-2 bg-yellow-200 text-yellow-800 py-1 px-3 rounded-md shadow-md">
            {selectedGenre}
          </span>
        </h2>

        <div className="container mx-auto px-4">
          {comics.length > 0 ? (
            comics.map((comic, index) => (
              <div
                key={index}
                className="comic-card mb-1 flex flex-col items-center cursor-pointer w-full"
                onClick={() => handleComicClick(comic)}
              >
                <img
                  src={comic.coverLink}
                  alt={`${comic.title} cover`}
                  className="comic-cover w-1/2 md:w-40 h-auto mb-1 rounded-lg object-cover"
                  loading="lazy"
                />

                <div className="comic-info p-6 bg-white rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105 w-full">
                  <div className="mb-1">
                    <strong className="text-xl font-extrabold text-gray-900">
                      {comic.title}
                    </strong>
                  </div>
                  <div className="mb-1 text-sm font-medium text-indigo-600">
                    {comic.type}
                  </div>
                  <div
                    className={`mb-1 text-lg font-semibold ${
                      comic.status === "Ongoing"
                        ? "text-green-500"
                        : comic.status === "Complete"
                        ? "text-red-500"
                        : "text-blue-500"
                    }`}
                  >
                    <strong>{comic.status}</strong>
                  </div>
                  <div>
                    <i className="text-gray-600">
                      {comic.sinopsis
                        ? comic.sinopsis.substring(0, 180) + "..."
                        : ""}
                    </i>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-600">
              No comics found for this genre.
            </p>
          )}
        </div>

        {/* Pagination Controls */}
        <div className="pagination-controls mt-4">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page <= 1}
            className="mr-2 px-4 py-2 bg-gray-200 text-gray-700 rounded-md"
          >
            Previous
          </button>
          {page < totalPages && (
            <button
              onClick={() => handlePageChange(page + 1)}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md"
            >
              Next
            </button>
          )}
        </div>

        {/* Display current page number */}
        <div className="mt-2">
          Page {page} of {totalPages}
        </div>
      </div>
    </HelmetProvider>
  );
};

export default GenreKomik;
