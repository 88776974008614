import React, { useState } from "react";
import Bebasbacakomik from "../assets/bebasbacakomik-title.png";
import { useNavigate } from "react-router-dom";


function Header() {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchClick = () => {
    console.log("Pencarian:", searchQuery);
  };

  const handleHome = () => {
    navigate("/"); // Arahkan ke path /about
  };

  return (
    <div>
      <header className="relative flex flex-col sm:flex-row items-center justify-between h-auto sm:h-16 px-4 py-2 bg-white shadow-md">
        <div
          className="flex items-center justify-center mb-2 sm:mb-0"
          id="header"
        >
          <img
            src={Bebasbacakomik}
            alt="BebasBacaKomik.com"
            className="h-6 sm:h-7 drop-shadow-md"
          />
        </div>
        <nav className="flex flex-wrap items-center justify-center space-x-4">
          <a
            onClick={handleHome}
            className="text-gray-700 hover:text-gray-900 font-bold text-lg sm:text-2xl"
          >
            Home
          </a>
          <span className="border-l border-gray-300 h-4 sm:h-6"></span>

          <a
            href="koleksi-terbaru"
            className="text-gray-700 hover:text-gray-900 font-bold text-lg sm:text-2xl"
          >
            Baru
          </a>
          <span className="border-l border-gray-300 h-4 sm:h-6"></span>
          <a
            href="koleksi-terpopuler"
            className="text-gray-700 hover:text-gray-900 font-bold text-lg sm:text-2xl"
          >
            Populer
          </a>
          <span className="border-l border-gray-300 h-4 sm:h-6"></span>
          <a
            href="pencarian"
            className="text-gray-700 hover:text-gray-900 font-bold text-lg sm:text-2xl"
          >
            Pencarian
          </a>
        </nav>

        {/* <div className="flex items-center mt-2 sm:mt-0">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Cari komik..."
            className="border rounded px-2 py-1 text-gray-700 focus:outline-none focus:ring-2 focus:ring-orange-500"
          />
          <button
            onClick={handleSearchClick}
            className="ml-2 px-4 py-1 bg-orange-500 text-white rounded hover:bg-orange-600"
          >
            Cari
          </button>
        </div> */}
      </header>
    </div>
  );
}

export default Header;
