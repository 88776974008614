import React, { useEffect } from "react";
import "./style/ScrollUp.css";

const ScrollUp = () => {
  // Gunakan useEffect untuk mengatur event scroll hanya sekali
  useEffect(() => {
    const handleScroll = () => {
      const scrollUp = document.querySelector(".scrollup");
      // Tampilkan ketika scrollY lebih dari 560px
      if (window.scrollY >= 560) {
        scrollUp.classList.add("show-scroll");
      } else {
        scrollUp.classList.remove("show-scroll");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <a href="#header" className="scrollup">
      <button>
        <i className="fas fa-arrow-up" style={{color:"white"}}></i>
      </button>
    </a>
  );
};

export default ScrollUp;
