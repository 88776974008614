import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./style/RakBuku.css";

const RakBuku = () => {
  const [history, setHistory] = useState([]);
  const [bookmark, setBookmark] = useState([]);
  const [later, setLater] = useState([]);

  const [showAll, setShowAll] = useState(false);

  const navigate = useNavigate();

  const handleClick = () => {
    setShowAll(true);
  };

  useEffect(() => {
    // Ambil data dari localStorage
    const savedHistory = localStorage.getItem("history");
    // console.log("savedHistory", savedHistory);

    // Cek apakah ada data dan apakah berupa array
    if (savedHistory) {
      try {
        const parsedHistory = JSON.parse(savedHistory);
        if (Array.isArray(parsedHistory)) {
          setHistory(parsedHistory);
        }
      } catch (error) {
        console.error("Gagal parsing history dari localStorage", error);
      }
    }

    const savedBookmark = localStorage.getItem("bookmarks");
    // console.log("savedBookmark", savedBookmark);

    // Cek apakah ada data dan apakah berupa array
    if (savedBookmark) {
      try {
        const parsedBookmark = JSON.parse(savedBookmark);
        if (Array.isArray(parsedBookmark)) {
          setBookmark(parsedBookmark);
        }
      } catch (error) {
        console.error("Gagal parsing bookmark dari localStorage", error);
      }
    }

    const savedLater = localStorage.getItem("later");

    // Cek apakah ada data dan apakah berupa array
    if (savedLater) {
      try {
        const parsedSavedLater = JSON.parse(savedLater);
        if (Array.isArray(parsedSavedLater)) {
          setLater(parsedSavedLater);
        }
      } catch (error) {
        console.error("Gagal parsing baca nanti dari localStorage", error);
      }
    }
  }, []);

  const handleComicClickHistory = (title, chapter) => {
    // navigate(`/${comic}`); // Navigate to the DetailKomik page with the comic path
    const formattedChapterTitle = title
      .replace(/chapter.*$/i, "") // Menghapus "Chapter" dan semua setelahnya
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-") // Mengganti karakter selain huruf dan angka dengan "-"
      .replace(/^-+|-+$/g, ""); // Menghapus "-" di awal dan akhir

    const formattedChapterChapter = chapter
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-+|-+$/g, "");

    console.log("handleComik", formattedChapterTitle);

    navigate(`/${formattedChapterTitle}/${formattedChapterChapter}`); // Navigate to the DetailKomik page with the comic path
    // navigate(`/${path}`);
  };

  const handleComicClickBookmark = (title) => {
    const formattedChapterTitle = title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-+|-+$/g, "");

    navigate(`/${formattedChapterTitle}`); // Navigate to the DetailKomik page with the comic path
  };

  const handleComicClickLater = (title) => {
    const formattedChapterTitle = title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-+|-+$/g, "");

    navigate(`/${formattedChapterTitle}`); // Navigate to the DetailKomik page with the comic path
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap", // Agar kolom bisa "wrap" ke bawah saat layar kecil
      }}
      id="rak-buku"
    >
      {/* Kolom History */}
      <div
        style={{
          flex: 1,
          margin: "0 10px",
          minWidth: "250px", // Lebar minimum tiap kolom
          marginBottom: "20px", // Menambah jarak bawah pada tampilan mobile
        }}
      >
        <h3 style={{ fontWeight: "600", fontSize: "36px" }}>History</h3>
        {history.length > 0 ? (
          <ul>
            {history
              .slice(0, showAll ? history.length : 3)
              .map((item, index) => (
                <li
                  key={index}
                  onClick={() =>
                    handleComicClickHistory(item.title, item.chapter)
                  }
                  style={{ cursor: "pointer" }} // Menambahkan pointer saat hover
                >
                  <span style={{ fontWeight: "bold", color: "black" }}>
                    {index + 1}.{" "}
                  </span>
                  <span style={{ color: "blue" }}>
                    {/* {console.log("item.title", item.title)} */}
                    {item.title} - {item.chapter}
                  </span>
                </li>
              ))}
          </ul>
        ) : (
          <p>Tidak ada history.</p>
        )}

        {/* Tombol hanya muncul jika jumlah history lebih dari 3 dan belum menampilkan semua */}
        {history.length > 3 && !showAll && (
          <button onClick={handleClick} className="button-open-list">
            Lihat seluruh Riwayat
          </button>
        )}
      </div>

      {/* Kolom Bookmark */}
      <div
        style={{
          flex: 1,
          margin: "0 10px",
          minWidth: "250px", // Lebar minimum tiap kolom
          marginBottom: "20px", // Menambah jarak bawah pada tampilan mobile
        }}
      >
        <h3 style={{ fontWeight: "600", fontSize: "36px" }}>Bookmark</h3>
        {bookmark.length > 0 ? (
          <ul>
            {bookmark
              .slice(0, showAll ? bookmark.length : 3)
              .map((item, index) => (
                <li
                  key={index}
                  onClick={() => handleComicClickBookmark(item)}
                  style={{ cursor: "pointer" }} // Menambahkan pointer saat hover
                >
                  <span style={{ fontWeight: "bold", color: "black" }}>
                    {index + 1}.{" "}
                  </span>{" "}
                  {/* Index bold dan hitam */}
                  <span style={{ color: "blue" }}>{item}</span>{" "}
                  {/* Item berwarna biru */}
                </li>
              ))}
          </ul>
        ) : (
          <p>Tidak ada bookmark.</p>
        )}

        {/* Tombol hanya muncul jika jumlah bookmark lebih dari 3 dan belum menampilkan semua */}
        {bookmark.length > 3 && !showAll && (
          <button onClick={handleClick} className="button-open-list">
            Lihat seluruh Bookmark
          </button>
        )}
      </div>

      {/* Kolom Baca Nanti */}
      <div
        style={{
          flex: 1,
          margin: "0 10px",
          minWidth: "250px", // Lebar minimum tiap kolom
          marginBottom: "20px", // Menambah jarak bawah pada tampilan mobile
        }}
      >
        <h3 style={{ fontWeight: "600", fontSize: "36px" }}>Baca Nanti</h3>
        {later.length > 0 ? (
          <ul>
            {later.slice(0, showAll ? later.length : 3).map((item, index) => (
              <li
                key={index}
                onClick={() => handleComicClickLater(item)}
                style={{ cursor: "pointer" }} // Menambahkan pointer saat hover
              >
                <span style={{ fontWeight: "bold", color: "black" }}>
                  {index + 1}.{" "}
                </span>{" "}
                {/* Index bold dan hitam */}
                <span style={{ color: "blue" }}>{item}</span>{" "}
                {/* Item berwarna biru */}
              </li>
            ))}
          </ul>
        ) : (
          <p>Tidak ada nanti.</p>
        )}

        {/* Tombol hanya muncul jika jumlah later lebih dari 3 dan belum menampilkan semua */}
        {later.length > 3 && !showAll && (
          <button onClick={handleClick} className="button-open-list">
            Lihat seluruh daftar baca
          </button>
        )}
      </div>
    </div>
  );
};

export default RakBuku;
