import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { Helmet, HelmetProvider } from "react-helmet-async";
import loadingGif from "../assets/bebas-baca-komik-loading-gif.gif";

const KoleksiTerbaru = () => {
  const { selectedGenre } = useParams();
  const [comics, setComics] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const baseUrl =
          window.location.hostname === "localhost"
            ? process.env.REACT_APP_DEVELOPMENT_URL
            : process.env.REACT_APP_PRODUCTION_URL;

        const response = await fetch(`${baseUrl}/public/komik-terbaru`);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const comics = await response.json();
        // console.log("terbaru", comics); // Log the actual data
        setComics(comics);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching data from backend:", error);
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    fetchData();
  }, []);

  const handleComicClick = (comic) => {
    navigate(`/${comic.path}`);
  };

  const handleImageError = (event) => {
    event.target.src = "https://bebasbacakomik.com/logo192.png";
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src={loadingGif} alt="Loading..." />
      </div>
    );
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>Koleksi Terbaru - Bebas Baca Komik</title>
        <meta
          name="description"
          content="Download Koleksi terbaru komik yang bisa Anda download secara gratis. Baca komik terbaru dengan berbagai genre yang menarik."
        />
        <meta
          name="keywords"
          content="download komik terbaru, baca komik gratis, download komik, komik online, genre komik"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Koleksi Terbaru - Bebas Baca Komik"
        />
        <meta
          property="og:description"
          content="Koleksi terbaru komik yang bisa Anda download secara gratis. Baca komik terbaru dengan berbagai genre yang menarik."
        />
        <meta
          property="og:image"
          content="https://bebasbacakomik.com/logo512.png"
        />
        <meta
          property="og:url"
          content="https://bebasbacakomik.com/koleksi-terbaru"
        />

        {/* Twitter */}
        <meta
          name="twitter:card"
          content="https://bebasbacakomik.com/logo512.png"
        />
        <meta
          name="twitter:title"
          content="Koleksi Terbaru - Bebas Baca Komik"
        />
        <meta
          name="twitter:description"
          content="Koleksi terbaru komik yang bisa Anda download secara gratis. Baca komik terbaru dengan berbagai genre yang menarik."
        />
        <meta
          name="twitter:image"
          content="https://bebasbacakomik.com/logo512.png"
        />
      </Helmet>
      <Header />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="container mx-auto px-4 mt-4">
          {comics.map((comic) => (
            <div
              key={comic.id}
              className="relative cursor-pointer mb-5"
              onClick={() => handleComicClick(comic)}
            >
              <div className="bg-gray-100 p-4 rounded-lg shadow-md">
                <img
                  src={comic.imgSrc}
                  alt={`${comic.title} cover`}
                  className="w-full md:w-40 h-auto rounded-lg object-cover"
                  loading="lazy"
                  onError={handleImageError}
                />
                <div
                  className="absolute top-2 right-2 bg-blue-600 text-white px-2 py-1 rounded"
                  style={{ fontSize: "0.875rem" }} // Adjust font size as needed
                >
                  {comic.chapter}
                </div>
                <div className="mt-2">
                  <h2 className="text-lg font-bold">{comic.judul}</h2>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </HelmetProvider>
  );
};

export default KoleksiTerbaru;
