import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import firebaseConfig from "../firebaseConfig";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import "./style/terpopuler.css"

const MangaTerpopuler = () => {
  const [comicData, setComicData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const navigate = useNavigate();

  const [imageStatus, setImageStatus] = useState({});

  useEffect(() => {
    displayData.forEach((komik) => {
      const img = new Image();
      img.src = komik.imgSrc;

      img.onload = () => {
        setImageStatus((prevStatus) => ({
          ...prevStatus,
          [komik.imgSrc]: true,
        }));
      };

      img.onerror = () => {
        setImageStatus((prevStatus) => ({
          ...prevStatus,
          [komik.imgSrc]: false,
        }));
      };
    });
  }, [displayData]);

  // Fetch data from Firestore
  useEffect(() => {
    const fetchData = async () => {
      try {
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
        const snapshot = await getDocs(collection(db, "v2-komik_rilisan_banyak"));
        let comic = [];
        snapshot.forEach((doc) => {
          comic.push({ ...doc.data(), id: doc.id });
        });
        // Sort comics by title
        comic.sort((a, b) => (a.judul > b.judul ? 1 : -1));
        setComicData(comic);
      } catch (error) {
        console.error("Error reading documents:", error);
      }
    };
    fetchData();
  }, []);

  // Update display data based on screen size
  useEffect(() => {
    const updateDisplayData = () => {
      const isDesktop = window.innerWidth >= 768;
      setDisplayData(comicData.slice(0, isDesktop ? 6 : 6));
    };

    updateDisplayData();
    window.addEventListener("resize", updateDisplayData);

    return () => window.removeEventListener("resize", updateDisplayData);
  }, [comicData]);

  // Event handler when an image is clicked
  const handleComicClick = (comic) => {
    navigate(`/${comic}`); // Navigate to the DetailKomik page with the comic path
  };

  return (
    <div className="KomikRilisanBanyak">
      <div className="sub-title-weekly">Komik Rilisan Banyak</div>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-4 px-3 sm:px">
        {displayData.map((komik, index) => (
          <div key={index} className="relative flex flex-col items-center">
        {/* {console.log("komik", komik)} */}
            
            <img
              src={
                imageStatus[komik.imgSrc]
                  ? komik.imgSrc
                  : "https://bebasbacakomik.com/logo192.png"
              }
              alt={komik.title}
              className="cursor-pointer hover:opacity-80 transition duration-300 w-[100px] h-[150px] md:w-[150px] md:h-[200px] rounded-lg"
              // style={{borderRadius:"50px"}}
              onClick={() => handleComicClick(komik.path)}
            />
           <div
              className="text-left mt-2 font-bold text-gray-800 hover:text-indigo-600 transition duration-300 cursor-pointer mb-0"
              onClick={() => handleComicClick(komik.path)}
            >
              <h3 className="overflow-hidden whitespace-nowrap text-ellipsis">
                {komik.title && komik.title.length > 12
                  ? `${komik.title.slice(0, 22)}...`
                  : komik.title}
              </h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MangaTerpopuler;
